

:root {
    --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms; 
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  a {
    color: var(--text-color);
    text-decoration: none;;
  }
  /* Top Navigation Bar */
  
  /* <nav> */
  .navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item {
    width: 60px; 
    height: 60px;
    background-color: blue;
    /* calc(var(--nav-size) * 0.8); */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1e9;
    position: absolute;
  }
  
  /* Icon Button */
  .icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #484a4d;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }
  
  .icon-button svg { 
    fill: var(--text-color);
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    width: 35dvh;
    /* transform: translateX(-45%); */
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1dvh;
    overflow: hidden;
    transition: height var(--speed) ease;
  }

  .modalmenu {
    /* position: absolute; */
    width: 100%;
    /* transform: translateX(-45%); */
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1dvh;
    overflow: hidden;
    transition: height var(--speed) ease;
  }

  .menu {
    width: 100%;
  }
  
  .menu-title {
    height: 4dvh;
    width: 100%;
    font-weight: bold;
    align-items: center;
    text-align: center;
    font-size: 2.5dvh;
    border-radius: var(--border-radius);
    padding: 0px;
    margin-bottom: 1dvh;
    color: white;
    text-decoration: none;
    vertical-align: text-top;
    overflow-x: visible;
    white-space: nowrap;
  }

  .menu-item {
    height: 4dvh;
    display: flex;
    align-items: center;
    font-size: 2dvh;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 1dvh;
    margin: 0dvh;
    color: white;
    text-decoration: none;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: #525357;
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  .dropdown {
  
    transition: height var(--speed) ease;
  }
